import React, { useState } from 'react';
import './App.scss';

function App() {

  const [formData, setformData] = useState({
    name: '',
    email: '',
    query: '',
    cnum: ''
  });

  const setValues = (e: any) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  }

  const sendRequest = (e: any) => {
    e.preventDefault();
    console.log(formData);
  }

  return (
    <>
      <div className="col-12 p-0" id="home">
        <section className="headerSection col-12 p-0 d-flex justify-content-center" style={{ backgroundImage: "url('/assets/img/path.svg')" }} >
          <div className="col-xl-10 col-lg-10 col-12 p-0">
            <div className="col-12 p-0 overflow-hidden headerSectionF">
              <div className="col-4 float-left d-flex p-0 headerSectionFF">
                <img style={{ width: "16em" }} src="/assets/img/Custom Size.png" />
              </div>
              <div className="col-9 float-left p-0 headerSectionS">
                <ul>
                  <a href="#service"> <li>Services</li> </a>
                  <a href="#contact"> <li>Contact</li> </a>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="col-12 d-flex p-0 justify-content-center">
        <div className="col-xl-10 col-lg-10 col-12 p-0">

          <section className="headerDownsct">
            <div className="headerDownsctFc row">
              <div className="col-xl-7 col-lg-7 col-12 p-0">
                <div className="hdUn display-4 themetxtColor">EXCPECT THE UNEXPECTED</div>
                <div className="hdunl my-4">
                  <div>-Best Dialer Provider</div>
                  <div>-Complete Asterisk Solutions</div>
                  <div>-CRM and IVR Solutions</div>
                  <div>-Customized web Soltions</div>
                </div>
                <button className="btn-theme" data-toggle="modal" data-target="#exampleModalCenter">Wave Me</button>
              </div>
              <div className="col-xl-5 col-lg-5 col-12 p-0">
                <div>
                  <img style={{ width: "100%", height: "100%" }} src="/assets/img/kms.jpeg" />
                </div>
              </div>
            </div>
          </section>

          <section className="ourServices" id="service">
            <div className="ourServicesA">Our Services</div>
            <div className="ourServicesB">We Provide Best Quality Service</div>
            <div className="col-12 p-0 d-flex justify-content-center my-4">
              <div className="ourServicesC col-xl-7 col-lg-7 col-12 p-0">
                We Manage Your Enterprise Needs, so You Can Manage Your Business.
              </div>
            </div>


            <div className="col-xl-12 d-flex justify-content-center p-0">
              <div className="col-10 p-0 row m-0">

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr  ">
                  <div className="p-2">
                    <div className="showWrWr showWr1">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-star text-white"></i>
                      </div>
                      <div className="showWrSc">Creative Design</div>
                      <div className="showWrTc">
                        Our UI and UX experts are best in their work. More than 100 designs have been created and available at online.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr ">
                  <div className="p-2">
                    <div className="showWrWr showWr2">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-laptop text-white"></i>
                      </div>
                      <div className="showWrSc">Friendly Codes</div>
                      <div className="showWrTc">
                        Our codes are adaptive with your demands. And any other it worker can work on our code.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr3">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-headset text-white"></i>
                      </div>
                      <div className="showWrSc">Fast Support</div>
                      <div className="showWrTc">Fast powerfull and most secure network Solutions for smart Home & Business</div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr4">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-server text-white"></i>
                      </div>
                      <div className="showWrSc">Servers</div>
                      <div className="showWrTc">
                        We provide servers with backups and integrated SSD drive.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr5">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-tachometer-alt text-white"></i>
                      </div>
                      <div className="showWrSc">CRM</div>
                      <div className="showWrTc">
                        We have expertise in making content relationship management.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr6">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-robot text-white"></i>
                      </div>
                      <div className="showWrSc">Chat Bot</div>
                      <div className="showWrTc">
                        We can provide best Chat Bot with AI which can learn itself And provide better communication.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr7">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-cloud  text-white"></i>
                      </div>
                      <div className="showWrSc">SERVER</div>
                      <div className="showWrTc">
                        Robust servers with experts support team available 24X7 with minimum 100 Mbps bandwidth provides uninterrupted flawless services with maximum of 24 hour of critical issues dead line.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr8">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-headset text-white"></i>
                      </div>
                      <div className="showWrSc">GENUINE SUPPORT</div>
                      <div className="showWrTc">
                        Expert support team with genuine support as our team knows the value of every min. we have efficient training program for the clients where they are self educated to handle their account.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12 showWr">
                  <div className="p-2">
                    <div className="showWrWr showWr9">
                      <div className="showWrFc">
                        <i className="fa-4x fas fa-code text-white"></i>
                      </div>
                      <div className="showWrSc">DOVELOPMENT</div>
                      <div className="showWrTc">
                        Expert developers provides you all web solution from clients branding requirement to CRM development to High end Integrations. Our Developers delivers products that our client need not what they built.
                      </div>
                      <div className="showWrFFc">
                        <button className="">Read More</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </section>

          <section className="knowAb row m-0">
            <div className="col-xl-6 col-lg-6 col-12 p-0 knowAbFc p-5">
              <img src="/assets/img/kms2.jpeg" />
            </div>
            <div className="col-xl-6 col-lg-6 col-12 p-0 knowAbSc">
              <div className="knowAbScWr">


                <div className="col-12 p-0 overflow-hidden my-2">
                  <div className="col-3 d-flex float-left justify-content-center align-items-center">
                    <i className="fab fa-airbnb fa-2x text-success"></i>
                  </div>
                  <div className="col-9 float-left">
                    <div className=""><strong>Quality Predictive Dialer</strong></div>
                    <div className="frtxt pt-3">
                      Predictive mode of dialing birngs you 300% growth rate for existing agents. Software based dialers outshines above the hardware based dialer over server and maintainance cost and hassel free operations with Quedial support. Quedial provides best predictive dialer services.
                      </div>
                  </div>
                </div>

                <div className="col-12 p-0 overflow-hidden my-2">
                  <div className="col-3 d-flex float-left justify-content-center align-items-center">
                    <i className="fas fa-hourglass-half fa-2x text-success"></i>
                  </div>
                  <div className="col-9 float-left">
                    <div className=""><strong>QUALITY CALLING MINUTES</strong></div>
                    <div className="frtxt pt-3">
                      Quedial provides calling minutes at the least margin and better price from most of the competitors. We focus mostly on the quality of connectivity of routes rather than cheap and going for worst routes for the cost cutting. Quedial provides best predictive dialer services.
                    </div>
                  </div>
                </div>

                <div className="knowAbScWrFc d-none">Know About UiExpert</div>
                <div className="knowAbScWrSc mt-2 d-none">We always follow professional workflow an provide you best <br />service with reaselable costs.</div>
                <div className="knowAbScWrTc d-none">
                  <svg className="mr-2" fill="#3d37f1" width="40" height="40" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" /></svg>
                  <span>See my video Brief</span>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

      <div className="col-12 d-flex px-0 py-5 justify-content-center">
        <div className="ourstructure d-none" style={{ backgroundImage: "url(/assets/img/banner-2.jpg)" }} >
          <div className="ourstructuretlt">What is our Structure ?</div>
          <div className="col-12 d-flex justify-content-center p-0">
            <div className="col-10 ourstructuretxt">
              We are providing desktop management, server management (windows & linux both) & call center solutions with product ranging from soft predictive dialers to voip gateways from voip minutes to servers. Hosted best predictive dialer technology - Voip best predictive dialer technology - Web Based best predictive dialer technology. The development of the best predictive dialer technology goals the best performance for telesale. Whether your call center is inbound, outbound or blended, our products include all of the features you need to run your operations effectively.
            </div>
          </div>
          <div className="justify-content-center d-flex col-12 p-0">
            <div className="col-xl-10 col-lg-10 justify-content-center d-flex col-12 p-0">
              <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                <div className="orsWr">
                  <div className="orsWrFc">
                    <div><i className="fa-2x fas fa-cloud themeColor"></i></div>
                  </div>
                  <div className="orsWrSc">SERVER</div>
                  <div className="orsWrTc">
                    Robust servers with experts support team available 24X7 with minimum 100 Mbps bandwidth provides uninterrupted flawless services with maximum of 24 hour of critical issues dead line.
                    </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                <div className="orsWr">
                  <div className="orsWrFc">
                    <div><i className="fa-2x fas fa-cogs themeColor"></i></div>
                  </div>
                  <div className="orsWrSc">SIMPLE UI</div>
                  <div className="orsWrTc">
                    Robust servers with experts support team available 24X7 with minimum 100 Mbps bandwidth provides uninterrupted flawless services with maximum of 24 hour of critical issues dead line.
                    </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                <div className="orsWr">
                  <div className="orsWrFc">
                    <div><i className="fa-2x fas fa-headset themeColor"></i></div>
                  </div>
                  <div className="orsWrSc">GENUINE SUPPORT</div>
                  <div className="orsWrTc">
                    Robust servers with experts support team available 24X7 with minimum 100 Mbps bandwidth provides uninterrupted flawless services with maximum of 24 hour of critical issues dead line.
                    </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                <div className="orsWr">
                  <div className="orsWrFc">
                    <div><i className="fa-2x fas fa-code themeColor"></i></div>
                  </div>
                  <div className="orsWrSc">DOVELOPMENT</div>
                  <div className="orsWrTc">
                    Robust servers with experts support team available 24X7 with minimum 100 Mbps bandwidth provides uninterrupted flawless services with maximum of 24 hour of critical issues dead line.
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="col-12 d-flex p-0 justify-content-center">
        <div className="col-xl-10 col-lg-10 col-12 p-0">
          <section className="port my-5">
            <div className="ousc">Our Success</div>
            <div className="ouscts">View Our Portfolio in categories</div>

            <div className="col-12 p-0 row m-0 d-flex justify-content-center py-5">
              <div className="col-xl-10 col-lg-10 col-12 p-0 row m-0">

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-3">
                  <div className="portWr">
                    <div>
                      <div className="portWrData portWrData1"><span>137</span></div>
                      <div className="portWrtxt">PROJECT DONE</div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-3">
                  <div className="portWr">
                    <div>
                      <div className="portWrData portWrData2"><span>24</span></div>
                      <div className="portWrtxt">HAPPY CLIENT</div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-3">
                  <div className="portWr">
                    <div>
                      <div className="portWrData portWrData3"><span>0</span></div>
                      <div className="portWrtxt">RETURN CLIENT</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="col-12 d-flex p-0 justify-content-center">
        <div className="col-xl-10 col-lg-10 col-12 p-0">
          <section className="port my-5">
            <div className="ousc">Our Expertise</div>
            <div className="ouscts d-none">View Our Portfolio in categories</div>

            <div className="col-12 p-0 row m-0 d-flex justify-content-center py-5">
              <div className="col-xl-10 col-lg-10 col-12 p-0 row m-0">

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData1"><span>
                        <div><i className="fa-2x fab fa-goodreads themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">
                        QUALITY PREDICTIVE DIALER
                      </div>
                      <div className="portWrtxt">
                        Predictive mode of dialing birngs you 300% growth rate for existing agents. Software based dialers outshines above the hardware based dialer over server and maintainance cost and hassel free operations with Quedial support. Quedial provides best predictive dialer services.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData2"><span>
                        <div><i className="fa-2x fas fa-cash-register themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">QUALITY CALLING MINUTES</div>
                      <div className="portWrtxt">
                        Quedial provides calling minutes at the least margin and better price from most of the competitors. We focus mostly on the quality of connectivity of routes rather than cheap and going for worst routes for the cost cutting. Quedial provides best predictive dialer services.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData3"><span>
                        <div><i className="fa-2x fab fa-servicestack themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">QUALITY HOSTED SERVERS</div>
                      <div className="portWrtxt">
                        Quedial offers best in class servers on 100mbps and 1gbps networks with prompt support and best security features for safety from hackers and sip atackers. We offer both sahred and dedicated servers to fullfill our costomer's requirment at affordable price.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData4"><span>
                        <div><i className="fa-2x fas fa-network-wired themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">IVR SOLUTIONS</div>
                      <div className="portWrtxt">
                        Quedial IVR solution is unique, effective and compact in working. IVR solution where the server handels all your calls without iterference of humans. It's more like a virtual assistance for all the calls for your office which is more effevtive with the automated custom CRM or Ticketing
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData5"><span>
                        <div><i className="fa-2x fab fa-hive themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">NETWORK SOLUTIONS</div>
                      <div className="portWrtxt">
                        Quedial has given free of cost networkk solutions to many of our clients. We offer you best in class network engineers who have mastered specially in network desinging skills for maximum output with minimum resources, that fits perfectly in your budget. Our supoort is available 24 hours.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 p-3">
                  <div className="portWrXX">
                    <div>
                      <div className="portWrData portWrData6"><span>
                        <div><i className="fa-2x fab fa-stumbleupon-circle themeColor"></i></div>
                      </span></div>
                      <div className="portWrtxtW">CRM SOLUTIONS</div>
                      <div className="portWrtxt">
                        Quedial provides custom CRM integration with latest CRM avalable in market also we provide custom CRM solutions where we built the CRM and custom reporting modules according to the need of the process. We give empahsis on effective CRM at lower cost.
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="my-5 footPart" style={{ backgroundImage: "url(/assets/img/review.png)" }} >
        <div className="pt-5 footParttxt">What they say about us!</div>
        <div className="footParttxx">Customer reviews.</div>
        <div>
          <div className="bd-example">
            <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">

                <div className="carousel-item active">
                  <div className="fotre">
                    <div className="carousel-caption d-none d-md-block">
                      <div className="d-flex justify-content-center">
                        <img src="/assets/img/2036.jpg" className="d-block rounded-circle" style={{ width: "auto", height: "15em" }} alt="..." />
                      </div>
                      <h5 className="mt-2">Aji John</h5>
                      <p>
                        It was really amazing to use Qdial services. Amazing solution for ivr with a crm, thanks team Qdial.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="fotre">
                    <div className="carousel-caption d-none d-md-block">
                      <div className="d-flex justify-content-center">
                        <img src="/assets/img/sachet.jpg" className="d-block rounded-circle" style={{ width: "auto", height: "15em" }} alt="..." />
                      </div>
                      <h5 className="mt-2">Sachet Chanchavat</h5>
                      <p>
                        Qdial provides amazing services, delivers work in before time with expected results. Great job team Qdial.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="fotre">
                    <div className="carousel-caption d-none d-md-block">
                      <div className="d-flex justify-content-center">
                        <img src="/assets/img/commonUser.png" className="d-block rounded-circle" style={{ width: "auto", height: "15em" }} alt="..." />
                      </div>
                      <h5 className="mt-2">Pramod Sainy</h5>
                      <p>
                        Support team of qdial is very friendly and ready to help any time.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>

      </section>

      {/* <section className="my-5 keyFeature">
        <div className="col-12 d-flex justify-content-center">
          <img src="/assets/img/key_feature.svg" />
        </div>
      </section> */}

      <div className="col-12 p-0 d-flex justify-content-center">
        <div className="col-xl-10 col-lg-10 col-12 p-0">
          <section className="workFlow">
            <div className="row border m-0">
              <div className="col-xl-6 col-lg-6 col-12 p-0">
                <div className="workFlowFc">
                  <img src="/assets/img/mkk.svg" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-12 p-0">
                <div className="workFlowSc">
                  <div>
                    <div className="workFlowSctxt">Our Workflow :</div>
                    <div className="">
                      Always follow professional Workflow and provide you the best service with resealable costs.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="my-5 d-none">
        <div className="col-12 d-flex px-0 justify-content-center">
          <div className="ourstructure py-5" style={{ backgroundRepeat: "repeat", backgroundSize: "auto", backgroundImage: "url(/assets/img/bg1.jpg)" }} >
            <div className="justify-content-center d-flex col-12 p-0">
              <div className="col-xl-10 col-lg-10 justify-content-center d-flex col-12 p-0 row m-0">

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc ">
                      <div><i className="fa-2x fab fa-goodreads themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">QUALITY PREDICTIVE DIALER</div>
                    <div className="orsWrTc smtxt text-dark">
                      Predictive mode of dialing birngs you 300% growth rate for existing agents. Software based dialers outshines above the hardware based dialer over server and maintainance cost and hassel free operations with Quedial support. Quedial provides best predictive dialer services.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fas fa-cash-register themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">QUALITY CALLING MINUTES</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial provides calling minutes at the least margin and better price from most of the competitors. We focus mostly on the quality of connectivity of routes rather than cheap and going for worst routes for the cost cutting. Quedial provides best predictive dialer services.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fab fa-servicestack themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">QUALITY HOSTED SERVERS</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial offers best in class servers on 100mbps and 1gbps networks with prompt support and best security features for safety from hackers and sip atackers. We offer both sahred and dedicated servers to fullfill our costomer's requirment at affordable price.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fas fa-network-wired themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">IVR SOLUTIONS</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial IVR solution is unique, effective and compact in working. IVR solution where the server handels all your calls without iterference of humans. It's more like a virtual assistance for all the calls for your office which is more effevtive with the automated custom CRM or Ticketing
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fab fa-hive themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">NETWORK SOLUTIONS</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial has given free of cost networkk solutions to many of our clients. We offer you best in class network engineers who have mastered specially in network desinging skills for maximum output with minimum resources, that fits perfectly in your budget.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fab fa-stumbleupon-circle themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">CRM SOLUTIONS</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial provides custom CRM integration with latest CRM avalable in market also we provide custom CRM solutions where we built the CRM and custom reporting modules according to the need of the process. We give empahsis on effective CRM at lower cost.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fas fa-puzzle-piece themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">WEB DEVELOPMENT</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial also provides web devlopment for clients with latest framworks avalable. We built buisness class responsive websites to meet all your buisness requirments on all the devices. We have also fulfilled some unique features and requirments to our clients.
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-3">
                  <div className="orsWr bg-transparent">
                    <div className="orsWrFc">
                      <div><i className="fa-2x fas fas fa-hands-helping themeColor"></i></div>
                    </div>
                    <div className="orsWrSc font-weight-bolder text-dark">SUPPORT AND MANGMENT</div>
                    <div className="orsWrTc smtxt text-dark">
                      Quedial provides best in class promt support with negligible dowtimes for our services. Quedial servers are matained by our expert team with Teir I class sevice with 99.999% of uptime. Quedial also provides great securities from hackers and sip atackers.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 px-5 col-12 overflow-hidden" id="contact">
        <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-2 float-left">
          <div className="cntcntr">
            <div className="col-12 p-0 overflow-hidden py-3">
              <div className="col-4 float-left d-flex justify-content-center align-content-center align-items-center">
                <i className="fas fa-map-marker-alt fa-3x pt-3 text-primary"></i>
              </div>
              <div className="col-8 float-left">
                <div className="addr"><b>Address</b></div>
                <div className="addrC pt-2">3410 Club House Rd,Mobile, Alabama, 36605</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-2 float-left">
          <div className="cntcntr">
            <div className="col-12 p-0 overflow-hidden py-3">
              <div className="col-4 float-left d-flex justify-content-center align-content-center align-items-center">
                <i className="far fa-envelope fa-3x pt-3 text-success"></i>
              </div>
              <div className="col-8 float-left">
                <div className="addr"><b>Email</b></div>
                <div className="addrC pt-2">info@quedial.com</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-12 px-xl-4 px-lg-4 px-2 float-left">
          <div className="cntcntr">
            <div className="col-12 p-0 overflow-hidden py-3">
              <div className="col-4 float-left d-flex justify-content-center align-content-center align-items-center">
                <i className="fas fa-phone fa-3x pt-3 text-danger"></i>
              </div>
              <div className="col-8 float-left">
                <div className="addr"><b>Phone</b></div>
                <div className="addrC pt-2">+1 2514444198</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 p-0 d-flex justify-content-center footbg" style={{ backgroundImage: "url('/assets/img/path.svg')" }} >
        <div className="col-xl-10 col-lg-10 col-12 p-0">
          <section className="footer">
            <div className="col-12 p-0 row m-0">

              <div className="col-xl-3 col-lg-3 col-12 p-2 foot1 py-5">

                <div className="foot1Fc"><strong style={{ color: "#fd8217" }}>Q</strong>uedial</div>
                <div className="foot1Sc py-3">
                  Our developers and support staff are available 24 hours. If you have any query you contact us any time.
                </div>
                <div className="foot1Tc py-3">
                  <div className="scl">
                    <i className="fab fa-2x fa-facebook"></i>
                  </div>
                  <div className="scl">
                    <i className="fab fa-2x fa-twitter"></i>
                  </div>
                  <div className="scl">
                    <i className="fab fa-2x fa-google-plus"></i>
                  </div>
                  <div className="scl">
                    <i className="fab fa-2x fa-youtube"></i>
                  </div>
                </div>
                <div className="fntMk">2021 all rights reserved</div>
              </div>


              <div className="col-xl-3 col-lg-3 col-12 p-2 foot2 py-5">
                <div className="foot2Fc">Products</div>
                <ul>
                  <li className="text-dark font-weight-bold">Voice</li>
                  <li className="text-dark font-weight-bold">SMS</li>
                  <li className="text-dark font-weight-bold">Authentication</li>
                </ul>
              </div>

              <div className="col-xl-3 col-lg-3 col-12 p-2 foot2 py-5">
                <div className="foot2Fc">Features</div>
                <ul>
                  <li className="text-dark font-weight-bold">Crm Integration</li>
                  <li className="text-dark font-weight-bold">Auto Dialer</li>
                  <li className="text-dark font-weight-bold">Self Service</li>
                  <li className="text-dark font-weight-bold">Help & Support</li>
                </ul>
              </div>

              <div className="col-xl-3 col-lg-3 col-12 p-2 foot2 py-5">
                <div className="foot2Fc">Solutions</div>
                <ul>
                  <li className="text-dark font-weight-bold">Cloude Call Center Software</li>
                  <li className="text-dark font-weight-bold">Mobile Call Center</li>
                  <li className="text-dark font-weight-bold">Whatsapp Customer Service</li>
                  <li className="text-dark font-weight-bold">Digital Sales</li>
                </ul>
              </div>

            </div>
          </section>
        </div>
      </div>

      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Send Query</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <form method="POST" action="./form_handler.php">
                <div className="form-row">

                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Name</label>
                    <input type="text" name="name" required onChange={setValues} value={formData.name} className="form-control" id="inputEmail4" placeholder="Name" />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Contact Number</label>
                    <input type="number" name="cnum" onChange={setValues} value={formData.cnum} className="form-control" id="inputEmail4" placeholder="Contact Number" />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Email</label>
                    <input type="email" name="email" required onChange={setValues} value={formData.email} className="form-control" id="inputEmail4" placeholder="Email" />
                  </div>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Write Your Query</label>
                  <textarea name="query" onChange={setValues} required value={formData.query} className="form-control" id="exampleFormControlTextarea1" rows={3}>

                  </textarea>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-success px-3">Send</button>
                </div>
              </form>

            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default App;
